import { render } from "./MassTextPhoneLists.vue?vue&type=template&id=5cc90ba2"
import script from "./MassTextPhoneLists.vue?vue&type=script&lang=js"
export * from "./MassTextPhoneLists.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5cc90ba2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5cc90ba2', script)) {
    api.reload('5cc90ba2', script)
  }
  
  module.hot.accept("./MassTextPhoneLists.vue?vue&type=template&id=5cc90ba2", () => {
    api.rerender('5cc90ba2', render)
  })

}

script.__file = "src/views/MassTextPhoneLists.vue"

export default script