<template>
  <div>
    <div class="breadcrumb my-4">
      <span class="mt-4 inline text-2xl font-semibold text-gray-600">
        Phone Lists
      </span>
    </div>

    <div>
      <form
        @submit.prevent="addContactGroup"
        class="bg-white w-full flex-col md:flex-row flex justify-between p-2"
      >
        <input
          class="w-full px-3 mr-3 border-2 border-gray-100 py-2"
          required
          v-model="contactGroupLabel"
          placeholder="Name"
        />
        <button
          type="submit"
          class="bg-gray-700 text-white w-full py-2 rounded-full mt-3 lg:mt-0 lg:w-1/2 lg-sm md:ml-3 xl:w-1/5 "
        >
          Save Contact Group
        </button>
      </form>
    </div>

    <template v-if="contactGroups.length == 0">
      <div
        class="border-gray-300 border-2 mt-3 text-center py-3 text-red-500 text-base"
      >
        No contact groups yet.
      </div>
    </template>
    <template v-else>
      <div class="my-4 font-semibold pl-3">Name</div>

      <div class="border-t-2 border-gray-200 px-3 bg-white">
        <div
          class="w-full inline-flex py-3"
          v-for="contactGroup in contactGroups"
          :key="contactGroup.id"
        >
          <div class="w-1/3">
            <router-link
              :to="`/mass-text/phone-lists/${contactGroup.id}`"
              class="text-blue-400 cursor-pointer"
            >
              {{ contactGroup.contact_group_label }}
            </router-link>
          </div>
          <div class="w-1/3">
            <router-link
              class="text-blue-500 cursor-pointer"
              :to="`/mass-text/phone-lists/${contactGroup.id}`"
            >
              Edit
            </router-link>
          </div>
          <div class="w-1/3">
            <a
              class="text-red-400 cursor-pointer"
              @click="openConfirmationPopup(contactGroup)"
            >
              Delete
            </a>
          </div>
        </div>
      </div>
      <div class="border-t-2 border-gray-200 px-3 bg-white">
        <div class="w-full inline-flex py-3">
          <div class="w-1/3"></div>
          <div class="w-1/3"></div>
          <div class="w-1/3">{{ contactGroups.length }} Contact Groups</div>
        </div>
      </div>
    </template>

    <div
      class="h-screen w-full fixed top-0 right-0 bg-gray-700 opacity-25"
      v-if="showConfirmationPopup || showEditPopup"
    ></div>

    <!-- confirmation of deletion of contact group -->

    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showConfirmationPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Are you sure to delete
            <span class="text-blue-400">
              {{ contactGroup.contact_group_label }}
            </span>
          </div>
          <form @submit.prevent="deleteContactGroup">
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:w-24 md:mr-3 text-center"
                type="submit"
              >
                Yes
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-24 md:ml-3 md:mt-0"
                @click="showConfirmationPopup = false"
              >
                No
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- edit contact group -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="showEditPopup"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Edit Contact Group
          </div>
          <form @submit.prevent="updateContactGroup" class="px-4">
            <div class="items-center flex flex-col mt-7">
              <div class="flex flex-col mb-6 w-full mt-2">
                <input
                  class="border py-2 px-3 text-grey-darkest mt-1"
                  type="text"
                  placeholder="Name"
                  v-model="contactGroup.contact_group_label"
                  name="contact_group_label"
                  autocomplete="off"
                  required
                  id="contact_group_label"
                />
              </div>
            </div>
            <div class="flex justify-center flex-col md:flex-row px-2">
              <button
                class="flex-no-shrink w-full text-white py-2 px-4 rounded-full bg-gray-700 md:mr-3 text-center"
                type="submit"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{
                  isSubmitted
                    ? "Updating Contact Group..."
                    : "Update Contact Group"
                }}
              </button>
              <button
                class="flex-no-shrink text-gray-700 border-gray-700 border-2 py-2 px-4 rounded-full w-full mt-2 md:w-40 md:ml-3 md:mt-0"
                @click="showEditPopup = false"
                :disabled="isSubmitted"
                :class="isSubmitted ? 'opacity-75' : ''"
              >
                {{ isSubmitted ? "Please Wait..." : "Cancel" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = require("../firebaseConfig");

export default {
  name: "MassTextPhoneListsComponent",
  data: () => ({
    contactGroups: [],
    contactGroup: {},
    contactGroupLabel: "",
    showEditPopup: false,
    showConfirmationPopup: false,
    user: {},
  }),
  async mounted() {
    this.user = await this.getUserInfo();
    this.checkPageAccess();
    this.getBusinessInfo();
    this.getContactGroups();
  },
  methods: {
    getUserInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .get()
          .then((user) => {
            resolve({ id: user.id, ...user.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async checkPageAccess() {
      const business = await firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .get();
      if (!business.data().mass_text_active) {
        this.$router.push("/dashboard");
      }
    },
    getBusinessInfo() {
      return new Promise((resolve, reject) => {
        try {
          firebase.db
            .collection("users")
            .doc(firebase.auth.currentUser.uid)
            .onSnapshot((doc) => {
              resolve({
                id: doc.id,
                ...doc.data(),
              });
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    async getContactGroups() {
      const business = await this.getBusinessInfo();
      firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("contact_groups")
        .onSnapshot({ includeMetadataChanges: true }, (docs) => {
          this.contactGroups = [];
          docs.forEach((doc) => {
            this.contactGroups.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
    },
    async addContactGroup() {
      const business = await this.getBusinessInfo();
      firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("contact_groups")
        .add({
          contact_group_label: this.contactGroupLabel,
          contact_number: [],
        });
      this.contactGroupLabel = "";
      this.contactGroups = [];
    },
    openConfirmationPopup(contactGroup) {
      this.contactGroup = contactGroup;
      this.showConfirmationPopup = true;
    },
    async deleteContactGroup() {
      const business = await this.getBusinessInfo();

      await firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("contact_groups")
        .doc(this.contactGroup.id)
        .delete();
      this.showConfirmationPopup = false;
      this.getContactGroups();
    },
    async updateContactGroup() {
      const business = await this.getBusinessInfo();

      firebase.db
        .collection("businesses")
        .doc(business.business_id)
        .collection("contact_groups")
        .doc(this.contactGroup.id)
        .update({
          contact_group_label: this.contactGroup.contact_group_label,
        });
      this.showEditPopup = false;
    },
    openEditPopup(contactGroup) {
      this.contactGroup = { ...contactGroup };
      this.showEditPopup = true;
    },
  },
};
</script>
