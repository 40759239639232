<template>
  <MassTextPhoneListsComponent />
</template>

<script>
import MassTextPhoneListsComponent from '../components/MassTextPhoneListsComponent.vue'

export default {
  name: 'MassTextPhoneLists',
  components: {
    MassTextPhoneListsComponent,
  },
}
</script>
